<template>
  <div>
    <div class="px-lg-16 secondary">
        <v-breadcrumbs :items="breadcrumbs" :dark="getDarkMode('secondary')" class="secondary">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
    </div>
    <v-container class="px-lg-16" v-if="store && storeProduct">
      <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
      <v-dialog v-model="showContactConfirm" max-width="400">
        <v-card flat>
          <nice-header>Enquiry submitted</nice-header>
          <v-card-text>Your enquiry has been submitted - we will get back to you ASAP</v-card-text>
        </v-card>
      </v-dialog>

      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-carousel
                hide-delimiters
                :height="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                :class="$vuetify.breakpoint.smAndDown ? 'pb-8' : ''"
            >
              <v-carousel-item
                v-for="(item,i) in images"
                :key="i"
                contain
                :src="item.url"
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
            <span v-if="!hidePrices" v-show="$vuetify.breakpoint.mdAndUp">
              <nice-header>Rates</nice-header>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Rate
                      </th>
                      <th class="text-left">
                        Interval
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(plan, i) in storeProduct.duration"
                      :key="i"
                    >
                      <td>{{ store.currency.symbol }} {{ plan.price }}</td>
                      <td>{{ plan.interval }} x {{ plan.period }}</td>
                    </tr>
                  </tbody>
                </template>
                
              </v-simple-table>
            </span>
          </v-col>
            

          <v-col cols="12" md="6">
            <v-card flat>
              <v-card-text
                class="uppercase font-spaced title grey--text pb-0"
              >{{storeProduct.category.name}}</v-card-text>
              <v-card-title class="display-1 py-0">{{storeProduct.name}}</v-card-title>
              <v-card-actions>
                <ratings-dialog :reviews="storeProduct.reviews"/>
                <v-spacer />
                <v-btn icon x-small class="px-1 ml-3" target="_blank" href="https://twitter.com/share?ref_src=twsrc%5Etfw">
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>
                <v-btn icon x-small class="px-1" target="_blank" :href="facebookUrl">
                  <v-icon>mdi-facebook</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-text class="pt-0 title">{{storeProduct.description}}</v-card-text>

              <v-card-title>Make a booking</v-card-title>
              <v-subheader v-if="storeProduct.hireType.toLowerCase() === 'collection'">
                This product must be collected in person from {{ store.name }}
              </v-subheader>
              <v-subheader v-else-if="storeProduct.hireType.toLowerCase() === 'delivery'">
                {{ store.name }} will delivery this product to you<span v-if="storeProduct.maxDeliveryRadius">, up to a distance of {{storeProduct.maxDeliveryDistance}}</span>
              </v-subheader>
              <v-subheader v-else>
                This product can either be collected in store, or delivered to your address
              </v-subheader>
              <v-form
                v-model="valid"
                v-if="storeProduct.allowBookings && durationChoices.length > 0"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" v-if="storeProduct.sizes.length > 0">
                      <r-select
                        :label="store.labelOne"
                        :rules="[rules.required]"
                        v-model="form.size"
                        item-text="value"
                        :items="storeProduct.sizes"
                      ></r-select>
                    </v-col>
                    <v-col cols="12" v-if="storeProduct.genders.length > 0">
                      <r-select
                        :label="store.labelTwo"
                        v-model="form.gender"
                        :rules="[rules.required]"
                        :items="storeProduct.genders"
                      ></r-select>
                    </v-col>
                    <v-col cols="12" v-if="storeProduct.brands.length > 0">
                      <r-select
                        :label="store.labelThree"
                        v-model="form.brand"
                        :rules="[rules.required]"
                        :items="storeProduct.brands"
                      ></r-select>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-card-title class="pl-0">Select dates and quantity</v-card-title>
                    </v-col>
                    <date-time-picker-set
                      :store="store"
                    />
                    <v-col cols="12" md="6">
                      <text-field
                        v-model.number="form.quantity"
                        label="Number required"
                        :rules="[rules.required, rules.numeric]"
                        append-icon="mdi-plus-circle"
                        @click:append="bump"
                        prepend-inner-icon="mdi-minus-circle"
                        @click:prepend-inner="decrement"
                      ></text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <text-field
                        :label="hidePrices ? 'Items required' : 'Price'"
                        :value="price"
                        :error-messages="availabilityErrors"
                      ></text-field>
                    </v-col>          
                    <v-col cols="12" v-if="storeProduct.hireType === 'both'">
                      <r-select
                        :rules="[rules.required]"
                        label="Hire type"
                        :disabled="this.basket.lineItems.length > 0"
                        :items="hireTypes"
                        v-model="form.hireType"
                      ></r-select>
                    </v-col>          
                  </v-row>
                </v-card-text>
              </v-form>
              <v-card-actions>
                  <v-spacer/>
                  <btn
                    class="no-transform"
                    color="accent"
                    @click="addToBasket"
                    :loading="loading"
                    :disabled="!valid || !bookingWindows"
                  >
                    Add to basket
                  </btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import {storeProduct, createEnquiry, availableDates, availability, addItem, basket, bookingWindows} from "@/graphql";
import moment from "moment";
import { mapState, mapMutations, mapGetters } from "vuex";
import RatingsDialog from '@/components/RatingsDialog.vue'
import DateTimePickerSet from "@/components/DateTimePickerSet";

export default {
  components: { RatingsDialog, DateTimePickerSet },
  apollo: {
    basket,
    bookingWindows: {
      query: bookingWindows,
      variables () {
        return {
          product: this.$route.params.id,
          startDatetime: this.getStartDatetime,
          endDatetime: this.getEndDatetime,
          size: this.form.size,
          brand: this.form.brand,
          gender: this.form.gender,
        }
      },
    },
    availability: {
      query: availability,
      variables () {
        return {
          pk: parseInt(this.$route.params.id),
          size: this.form.size,
          brand: this.form.brand,
          gender: this.form.gender,
          date: this.form.date,
          quantity: this.form.quantity,
          plan: parseInt(this.form.plan)
        }
      },
      skip () {
        return !this.form.date || !this.form.quantity || !this.form.plan;
      }
    },
    storeProduct: {
      query: storeProduct,
      variables() {
        return {
          pk: parseInt(this.$route.params.id)
        };
      }
    },
    availableDates: {
      query: availableDates,
      variables() {
        return {
          catalog: this.store.hashId,
          month: this.month + 1,
          year: this.year
        };
      }
    }
  },
  data: () => ({
    navigateAway: false,
    valid: null,
    validContact: null,
    loading: false,
    month: moment().month(),
    year: moment().year(),
    contactForm: {
      name: null,
      date: null,
      email: null,
      phone: null,
      message: null
    },
    menu: false,
    form: {
      size: null,
      gender: null,
      brand: null,
      plan: null,
      quantity: 0,
      selectedTime: null,
      date: null,
      hireType: 'collection'
    },
    rules: {
      required: v => !!v || "Please select a value",
      numeric: v => !isNaN(v) || "Must be a valid number",
      email: v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    },
    error: false,
    errorMessage: null,
    showContactConfirm: false,
    hireTypes: [
      { text: 'Collection from store', value: 'collection' },
      { text: 'Delivery', value: 'delivery' }
    ]
  }),
  filters: {
    niceTime (val) {
        return moment.parseZone(val).format('LT')
    }
  },
  watch: {
    basket (basket) {
      if (basket && basket.lineItems.length > 0) {
        this.form.hireType = basket.lineItems[0].hireType.toLowerCase()
      }
    },
    storeProduct (product) {
      if (product && product.hireType === 'delivery') {
        this.form.hireType = 'delivery'
      }
    },
    "form.date": function(val) {
      this.month = moment(val).month();
      this.year = moment(val).year();
      this.selectedTime = null;
    }
  },
  methods: {
    ...mapMutations(['setStartDatetime', 'setEndDatetime']),
    allowedDates(val) {
        if (this.availableDates) {
            return this.availableDates.indexOf(val) > -1;
        }
        return false;
    },
    allowedEndDates (val) {
        if (moment(val).isBefore(this.startDatetime)) {
            return false
        }
        if (this.availableDates) {
            return this.availableDates.indexOf(val) > -1;
        }
        return false;
    },
    addToBasket () {
        this.loading = true;
        this.$apollo.mutate({
            mutation: addItem,
            variables: {
                plan: parseInt(this.bookingWindows.plan.id),
                quantity: this.form.quantity,
                intervalsRequired: this.bookingWindows.intervalsRequired,
                startTime: this.getStartDatetime,
                endTime: this.getEndDatetime,
                brand: this.form.brand,
                size: this.form.size,
                gender: this.form.gender,
                hireType: this.form.hireType
            }
        }).catch((err) => {
            this.error = true;
            this.errorMessage = err.message;
            this.loading = false
        }).then((result) => {
            if (result) {
              this.$apollo.queries.basket.refetch().then(() => {
                this.$router.push('/basket')
                this.loading = false;
              })
            }
        })  
    },
    bump() {
      if (!isNaN(this.form.quantity)) {
        this.form.quantity = parseInt(this.form.quantity) + 1;
      }
    },
    decrement() {
      if (!isNaN(this.form.quantity) && this.form.quantity > 0) {
        this.form.quantity = parseInt(this.form.quantity) - 1;
      }
    },
    submitForm() {
      this.loading = true;
      const { name, email, message, phone } = this.contactForm;
      this.$apollo
        .mutate({
          mutation: createEnquiry,
          variables: {
            catalog: this.store.hashId,
            product: this.storeProduct.id,
            name,
            email,
            message,
            phone
          }
        })
        .catch(error => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then(result => {
          if (result) {
            this.$refs.contactForm.reset();
            this.showContactConfirm = true;
            this.loading = false;
          }
        });
    }
  },
  computed: {
    ...mapState(["store", 'startDatetime', 'endDatetime']),
    ...mapGetters(['getStartDatetime', 'getEndDatetime', 'getDarkMode']),
    availabilityErrors () {
      if (this.bookingWindows && this.start && this.end) {
        const { assetCount } = this.bookingWindows;
        if (assetCount < this.form.quantity) {
          return 'No availability for the selected date & time/quantity combination - please try another date'
        }
      }
      return null
    },
    hidePrices () {
      if (this.storeProduct) {
        const { duration: plans } = this.storeProduct
        const paidPlans = plans.filter(plan => plan.price > 0)
        return paidPlans.length === 0
      }
      return false
    },
    start: {
      get () {
          return this.startDatetime
      },
      set (value) {
          this.setStartDatetime(value)
      }
    },
    end: {
      get () {
          return this.endDatetime
      },
      set (value) {
          this.setEndDatetime(value)
      }
    },
    price () {
      if (this.bookingWindows && this.bookingWindows.assetCount >= this.form.quantity && this.form.quantity > 0) {
        const price = this.bookingWindows.plan.price * this.bookingWindows.intervalsRequired * this.form.quantity
        const intervalCount = this.bookingWindows.intervalsRequired * this.bookingWindows.plan.interval
        const rateString = `${this.store.currency.symbol} ${this.bookingWindows.plan.price} per ${this.bookingWindows.plan.interval > 1 ? this.bookingWindows.plan.interval : ''} ${this.bookingWindows.plan.period.toLowerCase()}${this.bookingWindows.plan.interval > 1 ? 's' : ''}`
        const intervalString = `${this.bookingWindows.plan.period.toLowerCase()}${intervalCount > 0 ? 's' : ''}`
        if (this.hidePrices) {
          return `${this.form.quantity} x ${intervalCount} ${intervalString}`
        }
        return `${this.store.currency.symbol} ${price} (${this.form.quantity} x ${intervalCount} ${intervalString} @ ${rateString})`
      }
      return ' '
    },
    facebookUrl () {
      return `http://www.facebook.com/share.php?u=${window.location.href}`
    },
    average() {
      if (this.storeProduct.reviews.length > 0) {
        const ratings = this.storeProduct.reviews
          .map(rev => rev.starRating)
          .reduce((a, b) => a + b);
        return (ratings / this.storeProduct.reviews.length).toFixed(1);
      }
      return null;
    },
    durationChoices() {
      if (this.storeProduct) {
        return this.storeProduct.duration.map(dr => ({
          id: dr.id,
          text: `${dr.interval} ${dr.period}${dr.interval > 1 ? "s" : ""} (${
            this.store.currency.symbol
          } ${dr.price})`
        }));
      }
      return [];
    },
    images() {
      if (this.storeProduct) {
        return this.storeProduct.images;
      }
      return [];
    },
    breadcrumbs() {
      if (this.store) {
        let crumbs = [
          {
            text: this.store.name,
            href: '/'
          }
        ];
        if (this.storeProduct) {
          crumbs.push({
            text: this.storeProduct.category.name,
            href: `/#/products?category=${this.storeProduct.category.name}`
          });
          crumbs.push({
            text: this.storeProduct.name,
            disabled: true
          });
        }
        return crumbs;
      }
      return [];
    }
  }
};
</script>

<style scoped>
.uppercase {
  text-transform: uppercase;
}
.no-transform {
    text-transform: none;
}
.font-spaced {
  letter-spacing: 0.2em;
}
</style>
